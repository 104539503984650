<template>
    <div>
        <ve-line :data="chartData" :settings="chartSettings"></ve-line>
    </div>
</template>

<script>
    import VeLine from 'v-charts/lib/histogram.common'
    export default {
        name: "Index",
        components: {
            VeLine
        },
        data() {
            this.chartSettings = {
                metrics: [ '网页', '微信公众号','微博','吴江日报','吴江新闻','微信文章'],
                dimension: ['日期']
            }
            return {
                chartData: {
                    columns: ['日期', '网页', '微信公众号','微博','吴江日报','吴江新闻','微信文章'],
                    rows:[]
                }
            }
        },
        created() {
            this.getclassification()
        },
        methods:{
            getclassification(){
                this.$axios.post('/admin/acquisitiontask/getclassification').then(res => {
                    if (res.data){
                        var rows=[];
                        res.data.forEach((item,index) => {
                            var cal={
                                '日期':item.createTime,
                                '网页':item.wyCollectionQuantity,
                                '微信公众号':item.wxCollectionQuantity,
                                '微博':item.wbCollectionQuantity,
                                '吴江日报':item.wjrbCollectionQuantity,
                                '吴江新闻':item.wjxwCollectionQuantity,
                                '吴江文章':item.wxwzCollectionQuantity,
                            }
                            rows.push(cal)
                        })
                        this.chartData.rows=rows;
                        console.log('dsddffvgff',this.chartData)
                    }
                })
            },
        }
    }
</script>

<style scoped>
</style>
