<script>
  var language_url = 'tinymce/langs/zh_CN.js'
  var language = 'zh_CN'
  var oxide = '/tinymce/skins/ui/oxide'
  var dark = '/tinymce/skins/ui/oxide-dark'
  var content_dark = '/tinymce/skins/content/dark/content.css'
  var content_default = '/tinymce/skins/content/default/content.css'
  var plugins = ['link lists image code table  wordcount']
  var toolbar = ['searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample', 'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table  fontsizeselect  fontselect emoticons forecolor backcolor fullscreen']
 //var apiurl ='http://10.70.0.6:4433'
 // var apiurl ='http://10.36.154.9:4433'
  var apiurl ='https://caiji.hzjxsj.com/api'
  //var apiurl ='http://localhost:4433'
  export default {
    language_url,
    language,
    oxide,
    dark,
    content_dark,
    content_default,
    plugins,
    toolbar,
      apiurl
  }
</script>

